import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import tw, { styled } from 'twin.macro'

import {Layout} from '@components/layout/layout'

const Grid = styled('div')`
${tw`grid grid-flow-col grid-cols-2 auto-rows-auto gap-4 leading-normal`}
`

const GaleryTemplate:React.FC = ({data}) => {
  return (<Layout data={data}
    prop={{
      canonicalUrl: 'https://www.mediroot.pl/galeria/',
      title: 'Galeria zdjęć - usługi stomatologiczne | Mediroot',
      description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
    }}>
    <StaticImage
      src="../assets/images/hero.jpg"
      placeholder="blurred"
      alt="Hero image"
      layout="fullWidth"/>
   <Grid>
     Strona w budowie
   </Grid>
  </Layout>);
};

export default GaleryTemplate;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
